.nav-links {
  list-style: none;
  margin: 0;
  padding: 30px;
  width: 100%;
  height: 90px;
  line-height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: sticky;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.nav-links-scrolled {
  list-style: none;
  margin: 0;
  padding: 15px;
  height: 45px;
  line-height: 45px;
  width: 100%;
  position: sticky;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.nav-links li {
  margin: 0;
  padding: 0;
}

.nav-links-scrolled li {
  margin: 0;
  padding: 0;
}

.nav-links a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0;
  margin: 0 0;
}

.nav-links-scrolled a {
  border: 1px solid transparent;
  color: #292929;
  text-decoration: none;
  padding: 0;
  margin: 0 0;
}

.nav-links a:hover {
  color: white;
}

.nav-links-scrolled a:hover {
  color: white;
}

@media (min-width: 990px) {
  .nav-links {
    flex-direction: row;
  }

  .nav-links-scrolled {
    flex-direction: row;
  }

  .nav-links li {
    margin: 0 1rem;
  }
  .nav-links-scrolled li {
    margin: 0 1rem;
  }
  .nav-links a {
    color: #e6e6e6;
    text-decoration: none;
  }
  .nav-links-scrolled a {
    color: #e6e6e6;
    text-decoration: none;
  }
  .nav-links span {
    color: #e6e6e6;
    text-decoration: none;
    cursor: pointer;
  }

  .nav-links-scrolled span {
    color: #e6e6e6;
    text-decoration: none;
    cursor: pointer;
  }
}
