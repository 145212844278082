.whyus-span {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin: 1vh;
}

.whyus-title {
  color: #e6e6e6;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.whyus-title h2 {
  margin: 0;
  padding: 10px 0 0 0;
  font-weight: 300;
  font-size: 28px;
  font-family: Helvetica;
  padding-top: 50px;
}

.whyus-seperator {
  flex-grow: 1;
  border: none;
  max-height: 2px;
  min-height: 2px;
  background-color: #efbb20;
  width: 60px;
  margin: 0;
  position: absolute;
  padding: 0;
}

.whyus-grid {
  display: grid;
  max-width: 1356px;
  font-size: 13px;
  border-radius: 10px;
  margin: 0 auto;
  grid-gap: 50px;
  grid-template-rows: auto auto auto;
  padding-bottom: 50px;
}

.whyus-col-grid {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin: 0;
  background-color: rgba(44, 53, 61, 1);
  position: relative;
  padding: 0;
  top: 0;
}

.whyus-col-grid h3 {
  margin: 0.2rem 5rem;
  font-family: Helvetica;
  font-size: 20px;
  color: #e6e6e6;
  text-align: start;
  font-weight: 200;
  top: 0;
}

.whyus-col-grid h4 {
  margin: 0.2rem 5rem;
  font-family: Helvetica;
  font-size: 0.8rem;
  color: #e6e6e6;
  text-align: start;
  font-size: 13px;
  font-weight: 200;
  top: 0;
}

.icon-circle {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  border: 1px solid #a9a9a9;
  position: absolute;
  display: flex;
  justify-content: center;
  color: #e6e6e6;
}

@font-face {
  font-family: "fontello";
  src: url("../../../../shared/UIElement/fontello/font/fontello.eot?48632290");
  src: url("../../../../shared/UIElement/fontello/font/fontello.eot?48632290#iefix")
      format("embedded-opentype"),
    url("../../../../shared/UIElement/fontello/font/fontello.woff2?48632290")
      format("woff2"),
    url("../../../../shared/UIElement/fontello/font/fontello.woff?48632290")
      format("woff"),
    url("../../../../shared/UIElement/fontello/font/fontello.ttf?48632290")
      format("truetype"),
    url("../../../../shared/UIElement/fontello/font/fontello.svg?48632290#fontello")
      format("svg");
  font-weight: 800;
  font-style: normal;
}

@media (min-width: 990px) {
  .whyus-grid {
    grid-template-columns: auto auto auto;
  }
}
