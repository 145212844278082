.logo__place {
  position: absolute;
  width: 300px;
  height: 83px;
  top: 0px;
  overflow: visible;
}

.logo__place-scrolled {
  position: sticky;
  width: 100px;
  height: 43px;
  top: 0px;
}

@media (min-width: 990px) {
  .logo__place {
    justify-content: space-between;
  }
}
