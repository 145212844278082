.ourservices-grid {
  display: grid;
  max-width: 1356px;
  font-size: 13px;
  border-radius: 10px;
  margin: 0 auto;
  grid-gap: 50px;
  grid-template-rows: auto auto auto;
  padding-bottom: 50px;
}

.ourservices-span {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin: 1vh;
}

.ourservices-title {
  color: rgb(71, 65, 65);
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.ourservices-title h2 {
  color: #2c353d;
  margin: 0;
  padding: 10px 0 0 0;
  font-weight: 400;
  font-size: 28px;
  font-family: Helvetica;
  padding-top: 50px;
}

.ourservice-grid-seperator {
  flex-grow: 1;
  border: none;
  max-height: 2px;
  min-height: 2px;
  background-color: #efbb20;
  width: 60px;
  margin: 0;
  position: absolute;
}

.col-grid {
  width: 100%;
  height: 100%;
  padding: 0;
  border-radius: 10px;
  margin: 0;
  text-align: center;
  background-color: #f2f4f8;
}

.col-grid-seperator {
  border: none;
  max-height: 2px;
  min-height: 2px;
  background-color: #efbb20;
  width: 60px;
  position: absolute;
  margin: 0;
  left: 0;
}

.col-grid h3 {
  margin: 0.1rem 0;
  font-family: Helvetica;
  font-size: 0.8rem;
  color: black;
  text-align: start;
  font-size: 20px;
  font-weight: 200;
}

.col-grid h4 {
  margin: 0.1rem 0;
  font-family: Helvetica;
  font-size: 0.8rem;
  color: #9c9c9c;
  text-align: start;
  font-weight: 400;
}

.col-grid span {
  margin: 0 auto;
  justify-content: start;
  display: flex;
  margin: 1vh 0;
  position: relative;
}

.col-grid-image {
  border: 1px;
  margin: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 990px) {
  .ourservices-grid {
    grid-template-columns: auto auto auto;
  }
}
