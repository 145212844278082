.yellowbar-grid {
  display: grid;
  max-width: 1356px;
  margin: 0 auto;
  grid-gap: auto;
  grid-template-rows: auto auto;
}

.yellowbar-col-grid {
  width: 100%;
  height: 100%;
  margin: 0;
  position: relative;
  padding: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: start;
}

.yellowbar-col1-grid {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  top: 0;
  margin: 0;
  display: flex;
  justify-content: end;
  align-items: center;
  text-align: center;
}

.yellowbar-col-grid h1 {
  color: #f2f4f8;
  font-family: Helvetica;
  top: 0;
  margin: 0.2rem 0;
  font-size: 2rem;
  font-weight: 400;
  display: flex;
  text-align: center;
  justify-content: start;
  align-items: center;
}

.yellowbar-col1-grid button {
  border: 3px solid white;
  background: transparent;
  width: 200px;
  height: 50px;
  border-radius: 50px;
  color: white;
  margin: 0.2rem 0;
  top: 0;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.yellowbar-col1-grid link {
  text-decoration: none;
}

@media (min-width: 990px) {
  .yellowbar-grid {
    grid-template-columns: auto auto;
  }
}
