.solutionp-span {
  margin: 0 auto;
  justify-content: center;
  display: flex;
  margin: 1vh;
}

.solutionp-title {
  color: white;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
}

.solutionp-title h2 {
  color: #2c353d;
  margin: 0;
  padding: 10px 0 0 0;
  font-weight: 400;
  font-size: 28px;
  font-family: Helvetica;
  padding-top: 50px;
}

.solutionp-seperator {
  flex-grow: 1;
  border: none;
  max-height: 2px;
  min-height: 2px;
  background-color: #efbb20;
  width: 60px;
  margin: 0;
  position: absolute;
}

.solutionp-grid {
  display: grid;
  max-width: 1356px;
  font-size: 13px;
  border-radius: 10px;
  margin: 0 auto;
  grid-gap: 10px;
  grid-template-rows: auto auto auto auto;
}

.solutionp-col-grid {
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: rgba(44, 53, 61, 1);
  position: relative;
  padding: 0;
  top: 0;
  display: flex;
  justify-content: center;
}

.solutionp-col-grid img {
  cursor: pointer;
  width: 100%;
}

@media (min-width: 990px) {
  .solutionp-grid {
    grid-template-columns: auto auto auto auto;
  }
}
