.topbar {
  height: 90px;
  position: relative;
  background: #f2f4f8;
  max-width: 1356px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  z-index: 100;
}

.topbar-scrolled {
  height: 45px;
  position: sticky;
  background: #f2f4f8;
  max-width: 1356px;
  margin: 0 auto;
  top: 0;
  display: flex;
  align-items: center;
  z-index: 100;
}
