.buttombar {
  position: relative;
  height: 100%;
  width: 100%;
  top: 0;
  background: rgb(44, 53, 61, 1);
  margin: 0 auto;
  display: block;
  padding: 0;
}
