.slider {
  position: relative;
  height: 100%;
  display: block;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin: 0;
  top: 0;
  width: 100%;
  bottom: 0;
}

.image {
  width: 100%;
  height: 100%;

  max-height: 600px;
  margin: 0 auto;
  display: block;
}

.right-arrow {
  position: absolute;
  top: 50%;
  right: 32px;
  font-size: 3rem;
  z-index: 20;
  cursor: pointer;
  user-select: none;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  border: 2px solid white;
  display: flex;
  color: white;
  justify-content: center;
}

.left-arrow {
  position: absolute;
  top: 50%;
  left: 32px;
  font-size: 3rem;
  z-index: 20;
  cursor: pointer;
  user-select: none;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  align-items: center;
  text-align: center;
  border: 2px solid white;
  display: flex;
  color: white;
  justify-content: center;
}

.slide {
  opacity: 0;
  transition-duration: 1s ease;
  z-index: 10;
}

.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1);
}
