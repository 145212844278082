.references-title {
  color: #2c353d;
  margin: 0;
  padding: 0 0 0 0;
  font-family: Helvetica;
  padding-top: 10px;
  display: flex;
  justify-content: center;
}

.references-title h2 {
  color: #2c353d;
  margin: 0;
  padding: 10px 0 0 0;
  font-weight: 400;
  font-size: 28px;
  font-family: Helvetica;
  padding-top: 30px;
}

.references-seperator {
  flex-grow: 1;
  border: none;
  max-height: 2px;
  min-height: 2px;
  background-color: #efbb20;
  width: 60px;
  margin: 0;
  position: absolute;
  display: flex;
  justify-content: center;
}

.references h3 {
  margin: 0.5rem 0;
  font-family: Helvetica;
  color: #efbb20;
  text-align: start;
  font-size: 28px;
  font-weight: 200;
  margin: 50px;
}

.references-span {
  margin: 0 auto;
  justify-content: start;
  display: flex;
  margin: 1vh 0;
  position: relative;
  display: flex;
  justify-content: center;
}
