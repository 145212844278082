.main-header {
  width: 500px;
  height: 90px;
  overflow: visible;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  background: rgba(44, 53, 61, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
}

.main-header-scrolled {
  position: absolute;
  align-items: center;
  top: 0;
  right: 0;
  height: 45px;
  background: rgba(44, 53, 61, 1);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.26);
  padding: 0 1rem;
  z-index: 5;
  width: 500px;
}

.main-header {
  display: none;
}

.main-header-scrolled {
  display: none;
}

@media (min-width: 990px) {
  .main-header {
    display: block;
  }

  .main-header-scrolled {
    display: block;
  }
}
