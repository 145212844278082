.footer-grid {
  display: grid;
  max-width: 1356px;
  font-size: 13px;
  border-radius: 10px;
  margin: 0 auto;
  grid-gap: 50px;
  grid-template-rows: auto auto auto auto;
}

.footer-col-grid span {
  color: #e6e6e6;
  font-size: 13px;
  display: flex;
  justify-content: star;
  border-bottom: 1px solid #a9a9a9;
  border-top: 1px solid #a9a9a9;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-right: 50px;
  width: 70%;
  max-width: 70%;
  margin: 3vh 0 0 0;
}

.footer-col-grid ul {
  color: #e6e6e6;
  font-size: 13px;
  width: 100%;
  list-style: none;
  margin: 0;
  display: flex;
  justify-content: start;
  flex-direction: column;
  padding: 10px 0px;
}

.footer-col-grid li {
  color: #e6e6e6;
  font-size: 13px;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 10px 0;
}

.footer-col-grid a {
  color: #e6e6e6;
  font-size: 13px;

  margin: 0;
  text-decoration: none;
}

.footer-col-grid a:hover {
  color: #efbb20;
  font-size: 13px;
  margin: 0;
  text-decoration-line: underline;
}

.footer-col-grid {
  margin-bottom: 0;
  width: 100%;
  height: 100%;
}

.footer-col-grid h4 {
  color: #e6e6e6;
  margin: 0;
  font-family: "Helvetica Neue";
  font-size: 13px;
  font-weight: 400;
  padding: 20px 0;
}

.footer-col-grid h4 p {
  color: #e6e6e6;
  margin: 0;
  font-family: "Helvetica Neue";
  font-size: 13px;
  font-weight: 400;
}

.box {
  border: 1px;
  margin: 0;
  width: 100%;
  height: 100%;
}

.box iframe {
  margin: 0 0px;
  padding: 20px 0;
  width: 70%;
  height: 70%;
}

@media (min-width: 990px) {
  .footer-grid {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
}
