.main-navigation__menu-btn {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 0;
  cursor: pointer;
  right: 5%;
  position: fixed;
  align-items: center;
  top: 2%;
  z-index: 100;
}

.main-navigation__menu-btn-scrolled {
  width: 3rem;
  height: 3rem;
  background: transparent;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-right: 0;
  cursor: pointer;
  right: 5%;
  position: fixed;
  align-items: center;
  right: 5%;
  top: 0;
  z-index: 100;
}

.main-navigation__menu-btn span {
  display: block;
  width: 3rem;
  height: 2.5px;
  background: rgba(44, 53, 61, 1);
  top: 0px;
}

.main-navigation__menu-btn-scrolled span {
  display: block;
  width: 3rem;
  height: 2px;
  background: rgba(44, 53, 61, 1);
  top: 0px;
}

.main-navigation__header-nav {
  display: none;
}

.main-navigation__drawer-nav {
  height: 100%;
  top: 90px;
}

.main-navigation__drawer-scrolled-nav {
  height: 100%;
  top: 45px;
  z-index: 100;
}

@media (min-width: 990px) {
  .main-navigation__menu-btn {
    display: none;
  }

  .main-navigation__header-nav {
    display: block;
  }
  .main-navigation-scrolled__header-nav {
    display: block;
    z-index: 100;
  }
  .main-navigation__menu-btn-scrolled {
    display: none;
  }
}
