.dropdown_submenu {
  width: 72%;
  position: absolute;
  top: 90px;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  left: 2.5rem;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.dropdown_submenu-scrolled {
  width: 72%;
  position: absolute;
  top: 45px;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  left: 2.5rem;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.dropdown_submenu li {
  background: rgba(44, 53, 61, 1);
  cursor: pointer;
}

.dropdown_submenu-scrolled li {
  background: rgba(44, 53, 61, 1);
  cursor: pointer;
}

.dropdown_submenu a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 10px;
}

.dropdown_submenu-scrolled a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 10px;
}

.dropdown_submenu li a:hover {
  color: white;
}

.dropdown_submenu_clicked {
  display: none;
}

.dropdown_submenu-scrolled_clicked {
  display: none;
}

.submenu-item_company {
  display: block;
  text-decoration: none;
  align-items: center;
  line-height: 30px;
}
