@import url("https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap");

* {
  box-sizing: border-box;
}

@font-face {
  font-family: "fontello";
  src: url("./shared/UIElement/fontello/font/fontello.eot?48632290");
  src: url("./shared/UIElement/fontello/font/fontello.eot?48632290#iefix")
      format("embedded-opentype"),
    url("./shared/UIElement/fontello/font/fontello.woff2?48632290")
      format("woff2"),
    url("./shared/UIElement/fontello/font/fontello.woff?48632290")
      format("woff"),
    url("./shared/UIElement/fontello/font/fontello.ttf?48632290")
      format("truetype"),
    url("./shared/UIElement/fontello/font/fontello.svg?48632290#fontello")
      format("svg");
  font-weight: normal;
  font-style: normal;
}

html {
  font-family: "Roboto", Roboto, "Helvetica Neue";
  font-size: 16px;
}

body {
  margin: 0;
  background: #f2f4f8;
}

.container {
  max-width: 1356px;
  height: 100vh;
  margin: 5vh auto;
  background: #f2f4f8;
  top: 0;
  padding: 0;
  z-index: 1;
}

.slider-container {
  background: #e9e9ea;
  top: 0;
  padding: 0;
  display: block;
  position: relative;
  margin: 0;
}

.yellowbar-container {
  position: relative;
  display: block;
  background-color: #efbb20;
  width: 100%;
  height: 100%;
  top: 0px;
  padding: 2vh;
  margin: 0;
}

.ourservices-container {
  position: relative;
  display: block;
  background-color: #f2f4f8;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
  margin: 0;
}

.whyus-container {
  position: relative;
  display: block;
  background-color: rgba(44, 53, 61, 1);
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
  margin: 0;
}

.solutionpartners-container {
  position: relative;
  display: block;
  background-color: #f2f4f8;
  width: 100%;
  height: 100%;
  top: 0;
  padding: 0;
  margin: 0;
}

.topbar-container {
  height: 90px;
  position: relative;
  background: #f2f4f8;
  margin: 0 auto;
  z-index: 100;
}

.topbar-container-scrolled {
  height: 45px;
  position: sticky;
  background: #f2f4f8;
  margin: 0 auto;
  top: 0;
  z-index: 100;
}

.slide-in-right-enter {
  transform: translateX(-100%);
}

.slide-in-right-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-right-exit {
  transform: translateX(0%);
  opacity: 1;
}

.slide-in-right-exit-active {
  transform: translateX(-100%);
  opacity: 0;
  transition: all 200ms;
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
