.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(63, 65, 64, 0.75);
  z-index: 10;
}

@media (min-width: 990px) {
  .backdrop {
    display: none;
  }
}
