.dropdown_submenu_services {
  width: 72%;
  position: absolute;
  top: 90px;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  left: 8rem;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.dropdown_submenu_services-scrolled {
  width: 72%;
  position: absolute;
  top: 45px;
  list-style: none;
  text-align: start;
  display: flex;
  flex-direction: column;
  left: 8rem;
  font-size: 13px;
  font-family: "Helvetica Neue";
}

.dropdown_submenu_services li {
  background: rgba(44, 53, 61, 1);
  cursor: pointer;
}

.dropdown_submenu_services-scrolled li {
  background: rgba(44, 53, 61, 1);
  cursor: pointer;
}

.dropdown_submenu_services a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: black;
  padding: 10px;
}

.dropdown_submenu_services-scrolled a {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: #f2f4f8;
  padding: 10px;
}

.dropdown_submenu_services li a:hover {
  color: white;
}

.dropdown_submenu_services_clicked {
  display: none;
}

.dropdown_submenu_services-scrolled_clicked {
  display: none;
}

.submenu-item_services {
  display: block;
  text-decoration: none;
  align-items: center;
  line-height: 30px;
}
