.sidebar-item {
  padding: 0.75em 1em;
  display: block;
  transition: background-color 0.15s;
  border-radius: 5px;
}
.sidebar-item:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.sidebar-title {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  cursor: pointer;
  color: rgb(62, 59, 59);
}
.sidebar-title span i {
  display: inline-block;
  width: 1.5em;
}
.sidebar-title .toggle-btn {
  cursor: pointer;
  transition: transform 0.3s;
}
.sidebar-item.open > .sidebar-title .toggle-btn {
  transform: rotate(180deg);
}
.sidebar-content {
  padding-top: 0.25em;
  height: 0;
  overflow: hidden;
}
.sidebar-item.open > .sidebar-content {
  height: auto;
}

.sidebar-item.plain {
  color: rgb(62, 59, 59);
  text-decoration: none;
}
.sidebar-item.plain:hover {
  text-decoration: underline;
}
.sidebar-item.plain i {
  display: inline-block;
  width: 1.7em;
}
