.side-drawer {
  position: fixed;
  right: 0px;
  top: 90px;
  z-index: 100;
  height: 100vh;
  width: 300px;
  background: white;
  box-shadow: 0 2px rgba(0, 0, 0, 0.26);
  overflow: auto;
}

.side-drawer-scrolled {
  position: fixed;
  right: 0px;
  top: 45px;
  z-index: 100;
  height: 100vh;
  width: 300px;
  background: white;
  box-shadow: 0 2px rgba(0, 0, 0, 0.26);
  overflow: auto;
}

@media (min-width: 990px) {
  .side-drawer {
    display: none;
  }
}
